import { combineReducers } from '@reduxjs/toolkit';

import app from './modules/app/reducer';
import search from './modules/search/reducer';
import searchPage from './modules/searchPage/reducer';

export default combineReducers({
  app,
  search,
  searchPage,
});
