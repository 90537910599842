import { connect } from 'react-redux';

import { getLoading, getNoResults, getCurrentPage, getPageVehicles, getTotalPages } from 'store/modules/searchPage/selectors';
import { getQuery } from 'store/modules/search/selectors';
import * as searchPageActions from 'store/modules/searchPage/actions';
import * as searchActions from 'store/modules/search/actions';
import SearchResults from './SearchResults';

const mapStateToProps = (state) => ({
  vehicles: getPageVehicles(state),
  currentPage: getCurrentPage(state),
  totalPages: getTotalPages(state),
  noResults: getNoResults(state),
  query: getQuery(state),
  loading: getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch(searchPageActions.load()),
  onChangePage: (pageNumber) => dispatch(searchPageActions.loadPage(pageNumber)),
  onSetQueryFilters: (filters) => dispatch(searchActions.setQueryFilters(filters)),
  onSetQuery: (query) => dispatch(searchActions.setQuery(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
