import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import rootSaga from 'saga/rootSaga';
import rootReducer from './rootReducer';

const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware],
  devTools: isDevelopment,
});

sagaMiddleware.run(rootSaga);

export default store;
