export const initialFilters = {
  makes: [],
  models: [],
  badges: [],
  price: [],
  airport: {},
};

export default {
  loading: false,
  location: {},
  startLocation: {},
  vehicles: [],
  pageVehicles: [],
  noResults: false,
  activeFilters: {
    ...initialFilters,
  },
  defaultFilters: {
    ...initialFilters,
  },
  availableFilters: {
    models: [],
    badges: [],
    airports: [],
  },
  editFilters: {
    ...initialFilters,
  },
  makesTree: [],
  sorting: [],
  currentPage: 1,
  pageSize: 4,
  totalPages: 0,
};
