import React from 'react';
import PropTypes from 'prop-types';

import { Link } from '@material-ui/core';
import { getHomeUrl } from '@evee/evee-ui.utils';
import useRegion from 'store/modules/app/hooks/useRegion';

const VehicleLink = ({ id, shortName, query, children, ...rest }) => {
  const { region } = useRegion();

  return (
    <Link
      href={`${getHomeUrl(region)}/vehicles/${shortName}/${id}${query}`}
      color="secondary"
      underline="none"
      {...rest}
    >
      {children}
    </Link>
  );
};

VehicleLink.propTypes = {
  id: PropTypes.string,
  shortName: PropTypes.string,
  query: PropTypes.string,
  children: PropTypes.node,
};

VehicleLink.defaultProps = {
  id: '',
  shortName: '',
  query: '',
  children: null,
};

export default VehicleLink;
