import * as actionTypes from './actionTypes';
import initialState from './initialState';

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_LOADING:
      return {
        ...state,
        loading: payload,
      };

    case actionTypes.SEARCH_SUCCESS:
      return {
        ...state,
        vehicles: payload,
        noResults: payload.length === 0,
      };

    case actionTypes.SET_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: {
          ...payload,
        },
      };

    case actionTypes.SET_ACTIVE_FILTER_VALUE:
      return {
        ...state,
        activeFilters: {
          ...state.activeFilters,
          [payload.filterName]: payload.filterValue,
        },
      };

    case actionTypes.SET_FIELD_VALUE:
      return {
        ...state,
        [payload.fieldName]: payload.fieldValue,
      };

    case actionTypes.SET_EDIT_FILTERS:
      return {
        ...state,
        editFilters: {
          ...state.editFilters,
          ...payload,
        },
      };

    case actionTypes.SET_AVAILABLE_FILTERS:
      return {
        ...state,
        availableFilters: {
          ...state.availableFilters,
          ...payload,
        },
      };

    case actionTypes.SET_DEFAULT_FILTERS:
      return {
        ...state,
        defaultFilters: {
          ...payload,
        },
      };

    case actionTypes.SET_EDIT_FILTERS_FIELD_VALUE:
      return {
        ...state,
        editFilters: {
          ...state.editFilters,
          [payload.fieldName]: payload.fieldValue,
        },
      };

    case actionTypes.SET_START_LOCATION:
      return {
        ...state,
        startLocation: {
          ...payload,
        },
      };

    case actionTypes.SET_PAGE_VEHICLES:
      return {
        ...state,
        pageVehicles: payload,
      };

    case actionTypes.SET_TOTAL_PAGES:
      return {
        ...state,
        totalPages: payload,
      };

    case actionTypes.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: payload,
      };

    case actionTypes.SET_ACTIVE_AIRPORT:
      return {
        ...state,
        activeFilters: {
          ...state.activeFilters,
          airport: payload,
        },
      };

    default:
      return state;
  }
};
