export const getVehicles = (state) => state.searchPage.vehicles;
export const getLocation = (state) => state.searchPage.location;
export const getStartLocation = (state) => state.searchPage.startLocation;
export const getActiveFilters = (state) => state.searchPage.activeFilters;
export const getAvailableFilters = (state) => state.searchPage.availableFilters;
export const getDefaultFilters = (state) => state.searchPage.defaultFilters;
export const getEditFilters = (state) => state.searchPage.editFilters;
export const getMakesTree = (state) => state.searchPage.makesTree;
export const getLoading = (state) => state.searchPage.loading;
export const getNoResults = (state) => state.searchPage.noResults;
export const getSorting = (state) => state.searchPage.sorting;
export const getCurrentPage = (state) => state.searchPage.currentPage;
export const getTotalPages = (state) => state.searchPage.totalPages;
export const getPageSize = (state) => state.searchPage.pageSize;
export const getPageVehicles = (state) => state.searchPage.pageVehicles;
export const getAirport = (state) => state.searchPage.activeFilters.airport;
