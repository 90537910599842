import { fork, all } from 'redux-saga/effects';

import listenAppInit from './listenAppInit';
import listenSetCurrency from './listenSetCurrency';

export default function* appSaga() {
  yield all([
    fork(listenAppInit),
    fork(listenSetCurrency),
  ]);
}
