import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import { ThemeProvider } from '@material-ui/core';

import { appInit } from 'store/modules/app/actions';

import SearchResults from 'common/components/SearchResults';
import Toast from 'common/components/Toast';

import './App.css';
import theme from './theme';

function App({ onLoad }) {
  const renderWidget = () => {
    const scriptElement = document.getElementById('search-results');
    if (scriptElement) {
      return <SearchResults />;
    }

    return null;
  };

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <BrowserRouter>
          <Switch>
            <Route path="/">{renderWidget()}</Route>
          </Switch>
        </BrowserRouter>

        <Toast />
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}

App.propTypes = {
  onLoad: PropTypes.func.isRequired,
};

const mapStateToProps = ({ app }) => ({
  ...app,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch(appInit()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
