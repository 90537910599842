import React from 'react';
import PropTypes from 'prop-types';

import VehicleLink from '../VehicleLink';

const SearchVehicleLink = ({ id, shortName, children, tripDates, airport }) => {
  let queryString = '';

  queryString +=
    tripDates.from && tripDates.to && !tripDates.flexible
      ? `?&startDate=${tripDates.from}&endDate=${tripDates.to}`
      : '';

  if (queryString.length > 0) {
    queryString += airport && airport.id ? `&locationId=${airport.id}` : '';
  } else {
    queryString += airport && airport.id ? `?&locationId=${airport.id}` : '';
  }

  return (
    <VehicleLink id={id} shortName={shortName} query={queryString}>
      {children}
    </VehicleLink>
  );
};

SearchVehicleLink.propTypes = {
  id: PropTypes.string,
  shortName: PropTypes.string,
  children: PropTypes.node,
  tripDates: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    flexible: PropTypes.bool.isRequired,
  }),
  airport: PropTypes.string,
};

SearchVehicleLink.defaultProps = {
  id: '',
  shortName: '',
  children: null,
  tripDates: {},
  airport: '',
};

export default SearchVehicleLink;
