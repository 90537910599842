import { call, put, select } from 'redux-saga/effects';
import moment from 'moment';
import qs from 'qs';
import _ from 'lodash';

import { apiService } from '@evee/evee-ui.services';
import { DEFAULT_TRIP_LENGTH, getDefaultSearchLocation } from '@evee/evee-ui.utils';

import * as appActions from 'store/modules/app/actions';
import * as appSelectors from 'store/modules/app/selectors';
import * as searchActions from 'store/modules/search/actions';
import * as searchPageActions from 'store/modules/searchPage/actions';
import * as searchSelectors from 'store/modules/search/selectors';

export const getFormattedDate = (date) => moment(date).format('YYYY-MM-DDTHH:mm');

export function getQueryString(location, filters = {}) {
  const { id, radius, latitude, longitude, name, zoom } = location;
  const { from, to, flexibleDates, makes, models, badges, minPrice, maxPrice } = filters;

  const endDate = to || moment(from).add(DEFAULT_TRIP_LENGTH, 'hours');

  const flexibleDatesQuery = `flexibleDates=${flexibleDates}`;
  const rangeQuery = `from=${getFormattedDate(from)}&to=${getFormattedDate(endDate)}`;
  const locationQuery = `radius=${radius}&latitude=${latitude}&longitude=${longitude}`;
  const locationIdQuery = id ? `locationId=${id}` : '';
  const nameQuery = name ? `name=${name}` : '';
  const zoomQuery = zoom ? `zoom=${zoom}` : '';
  const minPriceQuery = minPrice ? `minPrice=${minPrice}` : '';
  const maxPriceQuery = maxPrice ? `maxPrice=${maxPrice}` : '';

  const makesQuery = makes
    ? qs.stringify({ makes: makes.map((make) => make.name) }, { arrayFormat: 'indices' })
    : '';

  const modelsQuery = models
    ? qs.stringify({ models: models.map((model) => model.name) }, { arrayFormat: 'indices' })
    : '';

  const badgesQuery = badges ? qs.stringify({ badges }, { arrayFormat: 'indices' }) : '';

  return _.compact([
    flexibleDatesQuery,
    rangeQuery,
    locationQuery,
    locationIdQuery,
    nameQuery,
    zoomQuery,
    makesQuery,
    modelsQuery,
    badgesQuery,
    minPriceQuery,
    maxPriceQuery,
  ]).join('&');
}

export function* updateAirportSearch({ payload: searchLocation }) {
  try {
    let allAirports = yield select(searchSelectors.getAllAirports);
    if (!allAirports.length) {
      allAirports = yield call(apiService.location.getAirports, '', '');
      yield put(searchActions.setFieldValue('allAirports', allAirports));
    }

    if (searchLocation?.id && allAirports.some((a) => a.id === searchLocation.id)) {
      yield put(searchActions.setAirportSearch(true));
    } else {
      yield put(searchPageActions.setActiveAirport({}));
    }
  } catch (error) {
    yield put(appActions.showError(error.message));
  }
}

export function* setQueryFilters() {
  try {
    const region = yield select(appSelectors.getRegion);
    const headerLocation = yield select(searchSelectors.getLocation);
    const searchLocation = headerLocation || getDefaultSearchLocation(region);
    yield put(searchPageActions.setStartLocation(searchLocation));
    yield put(searchActions.updateAirportSearch(searchLocation));
  } catch (error) {
    yield put(appActions.showError(error.message));
  }
}
