import React from 'react';
import PropTypes from 'prop-types';

import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const Toast = ({ toast, onClose }) => {
  const { open, message, type } = toast;
  const handleClose = (event, reason) => {
    if (reason !== 'clickaway') onClose();
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={type} style={{ minWidth: 300 }}>
        <span style={{ fontSize: 13 }}>{message}</span>
      </Alert>
    </Snackbar>
  );
};

Toast.propTypes = {
  toast: PropTypes.shape({
    open: PropTypes.bool,
    message: PropTypes.string,
    type: PropTypes.string,
  }),
  onClose: PropTypes.func,
};

Toast.defaultProps = {
  toast: {
    open: false,
    type: '',
    message: '',
  },
  onClose: () => {},
};

export default Toast;
