import * as actionTypes from './actionTypes';

export const showError = (message) => ({
  type: actionTypes.SHOW_ERROR,
  payload: message,
});

export const showRequestError = () => ({
  type: actionTypes.SHOW_ERROR,
  payload: 'Request was unsuccessful',
});

export const showSuccess = (message) => ({
  type: actionTypes.SHOW_SUCCESS,
  payload: message,
});

export const closeToast = () => ({
  type: actionTypes.CLOSE_TOAST,
});

export const appInit = () => ({
  type: actionTypes.APP_INIT,
});

export const setRegion = (payload) => ({
  type: actionTypes.SET_REGION,
  payload,
});

export const setCurrency = (payload) => ({
  type: actionTypes.SET_CURRENCY,
  payload,
});
