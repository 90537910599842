import { fork, all } from 'redux-saga/effects';

import appSaga from './app';
import searchSaga from './search';
import searchPageSaga from './searchPage';

function* rootSaga() {
  yield all([
    fork(appSaga),
    fork(searchSaga),
    fork(searchPageSaga),
  ]);
}

export default rootSaga;
