const prefix = 'searchPage';

export const LOAD = `${prefix}/LOAD`;
export const SET_LOADING = `${prefix}/SET_LOADING`;
export const SEARCH = `${prefix}/SEARCH`;
export const SEARCH_SUCCESS = `${prefix}/SEARCH_SUCCESS`;

export const SET_FIELD_VALUE = `${prefix}/SET_FIELD_VALUE`;
export const SET_ACTIVE_FILTERS = `${prefix}/SET_ACTIVE_FILTERS`;
export const SET_DEFAULT_FILTERS = `${prefix}/SET_DEFAULT_FILTERS`;
export const SET_ACTIVE_FILTER_VALUE = `${prefix}/SET_ACTIVE_FILTER_VALUE`;
export const SET_ACTIVE_AIRPORT = `${prefix}/SET_ACTIVE_AIRPORT`;
export const FILTER_MAKES = `${prefix}/FILTER_MAKES`;
export const FILTER_MODELS = `${prefix}/FILTER_MODELS`;

export const SET_AVAILABLE_FILTERS = `${prefix}/SET_AVAILABLE_FILTERS`;
export const SET_EDIT_FILTERS = `${prefix}/SET_EDIT_FILTERS`;
export const SET_EDIT_FILTERS_FIELD_VALUE = `${prefix}/SET_EDIT_FILTERS_FIELD_VALUE`;

export const SET_START_LOCATION = `${prefix}/SET_START_LOCATION`;

export const LOAD_PAGE = `${prefix}/LOAD_PAGE`;
export const SET_PAGE_VEHICLES = `${prefix}/SET_PAGE_VEHICLES`;
export const SET_TOTAL_PAGES = `${prefix}/SET_TOTAL_PAGES`;
export const SET_CURRENT_PAGE = `${prefix}/SET_CURRENT_PAGE`;
