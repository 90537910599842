import * as actionTypes from './actionTypes';

export const load = () => ({
  type: actionTypes.LOAD,
});

export const search = () => ({
  type: actionTypes.SEARCH,
});

export const searchSuccess = (vehicles) => ({
  type: actionTypes.SEARCH_SUCCESS,
  payload: vehicles,
});

export const setActiveFilters = (filters) => ({
  type: actionTypes.SET_ACTIVE_FILTERS,
  payload: filters,
});

export const setDefaultFilters = (filters) => ({
  type: actionTypes.SET_DEFAULT_FILTERS,
  payload: filters,
});

export const setFieldValue = (fieldName, fieldValue) => ({
  type: actionTypes.SET_FIELD_VALUE,
  payload: { fieldName, fieldValue },
});

export const setLoading = (loading) => ({
  type: actionTypes.SET_LOADING,
  payload: loading,
});

export const setEditFilters = (filters) => ({
  type: actionTypes.SET_EDIT_FILTERS,
  payload: filters,
});

export const setAvailableFilters = (filters) => ({
  type: actionTypes.SET_AVAILABLE_FILTERS,
  payload: filters,
});

export const setStartLocation = (location) => ({
  type: actionTypes.SET_START_LOCATION,
  payload: location,
});

export const loadPage = (pageNumber) => ({
  type: actionTypes.LOAD_PAGE,
  payload: pageNumber,
});

export const setPageVehicles = (vehicles) => ({
  type: actionTypes.SET_PAGE_VEHICLES,
  payload: vehicles,
});

export const setTotalPages = (total) => ({
  type: actionTypes.SET_TOTAL_PAGES,
  payload: total,
});

export const setCurrentPage = (pageNumber) => ({
  type: actionTypes.SET_CURRENT_PAGE,
  payload: pageNumber,
});

export const setActiveAirport = (airport) => ({
  type: actionTypes.SET_ACTIVE_AIRPORT,
  payload: airport,
});
