import { connect } from 'react-redux';

import * as appActions from 'store/modules/app/actions';
import * as appSelectors from 'store/modules/app/selectors';
import Toast from './Toast';

const mapStateToProps = (state) => ({
  toast: appSelectors.getToast(state),
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(appActions.closeToast()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
