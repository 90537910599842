import * as actionTypes from './actionTypes';

export const setFieldValue = (fieldName, value) => ({
  type: actionTypes.SET_FIELD_VALUE,
  payload: { fieldName, value },
});

export const search = () => ({
  type: actionTypes.SEARCH,
});

export const setQueryFilters = (filters) => ({
  type: actionTypes.SET_QUERY_FILTERS,
  payload: filters,
});

export const setQuery = (query) => ({
  type: actionTypes.SET_QUERY,
  payload: query,
});

export const setAirportSearch = (payload) => ({
  type: actionTypes.SET_AIRPORT_SEARCH,
  payload,
});

export const updateAirportSearch = (location) => ({
  type: actionTypes.UPDATE_AIRPORT_SEARCH,
  payload: location,
});
