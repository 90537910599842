import { fork, all } from 'redux-saga/effects';

import listenSearch from './listenSearch';
import listenLoad from './listenLoad';
import listenFilterMakes from './listenFilterMakes';
import listenFilterModels from './listenFilterModels';
import listenLoadPage from './listenLoadPage';

export default function* searchPageSaga() {
  yield all([
    fork(listenSearch),
    fork(listenLoad),
    fork(listenFilterMakes),
    fork(listenFilterModels),
    fork(listenLoadPage),
  ]);
}
