import moment from 'moment';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import { all, call, put, select } from 'redux-saga/effects';

import {
  defaultAustraliaLocation,
  stringCompare,
} from '@evee/evee-ui.utils';

import { apiService } from '@evee/evee-ui.services';

import * as appActions from 'store/modules/app/actions';
import * as appSelectors from 'store/modules/app/selectors';
import * as searchPageActions from 'store/modules/searchPage/actions';
import * as searchPageSelectors from 'store/modules/searchPage/selectors';
import * as searchSelectors from 'store/modules/search/selectors';
import * as searchActions from 'store/modules/search/actions';
import { initialFilters } from 'store/modules/searchPage/initialState';

const enrichMakesWithID = (makes) =>
  makes.map((make) => ({
    id: nanoid(),
    name: make.name,
    models: make.models.map((model) => ({
      id: nanoid(),
      name: model.name,
      badges: model.badges.map((badge) => ({
        id: nanoid(),
        name: badge,
      })),
    })),
  }));

const getMakes = (makesTree) =>
  makesTree
    .map((make) => ({
      id: make.id,
      name: make.name,
    }))
    .sort((a, b) => stringCompare(a.name, b.name));

function getModels(makesTree) {
  const getSortedModels = (make) => ({
    make: {
      id: make.id,
      name: make.name,
    },
    models: make.models
      .map((model) => ({
        id: model.id,
        name: model.name,
      }))
      .sort((a, b) => stringCompare(a.name, b.name)),
  });

  return makesTree
    .map((make) => getSortedModels(make))
    .filter((e) => e.models.length > 0)
    .sort((a, b) => stringCompare(a.make.name, b.make.name));
}

function getBadges(makesTree) {
  const getSortedBadges = (make) =>
    make.models.map((model) => ({
      make: {
        id: make.id,
        name: make.name,
      },
      model: {
        id: model.id,
        name: model.name,
      },
      badges: model.badges
        .map((badge) => ({
          id: badge.id,
          name: badge.name,
        }))
        .sort((a, b) => stringCompare(a.name, b.name)),
    }));

  return makesTree
    .map((make) => getSortedBadges(make))
    .flat()
    .filter((e) => e.badges.length > 0)
    .sort((a, b) => stringCompare(a.make.name, b.make.name))
    .sort((a, b) => stringCompare(a.model.name, b.model.name));
}

function transformMakesTree(makesTree, makes, models, badges) {
  const mapBadges = (defaultBadges) =>
    defaultBadges
      .filter((badge) => badges.some((e) => e.id === badge.id))
      .map((badge) => badge.name);

  const mapModels = (defaultModels) =>
    defaultModels
      .filter((model) => models.some((e) => e.id === model.id))
      .map((model) => ({ name: model.name, badges: mapBadges(model.badges) }));

  return makesTree
    .filter((make) => makes.some((e) => e.id === make.id))
    .map((make) => ({ name: make.name, models: mapModels(make.models) }));
}

export function* search() {
  try {
    const { from, to, flexibleDates } = yield select(searchSelectors.getFilters);
    if (!moment(from).isValid() || !moment(to).isValid()) {
      return;
    }

    yield put(searchPageActions.setLoading(true));

    const headerLocation = yield select(searchSelectors.getLocation);
    const isAirportSearch = yield select(searchSelectors.getAirportSearch);

    if (isAirportSearch) {
      yield put(searchPageActions.setActiveAirport(headerLocation));
      yield put(searchActions.setAirportSearch(false));
    }

    const {
      makes,
      models,
      badges,
      airport,
      price: [minPrice, maxPrice],
    } = yield select(searchPageSelectors.getActiveFilters);

    const [sorting, makesTree] = yield all([
      select(searchPageSelectors.getSorting),
      select(searchPageSelectors.getMakesTree),
    ]);

    const queryLocation = headerLocation || defaultAustraliaLocation;

    const searchQuery = {
      // TODO: fix date format
      from: moment(from).utc(true).format('YYYY-MM-DDTHH:mm'),
      to: moment(to).utc(true).format('YYYY-MM-DDTHH:mm'),
      flexibleDates,
      latitude: queryLocation.latitude,
      longitude: queryLocation.longitude,
      radius: queryLocation.radius,
      makes: transformMakesTree(makesTree, makes, models, badges),
      minPrice,
      maxPrice,
      sort: sorting,
      locationIds: airport?.id && [airport.id],
    };

    const currency = yield select(appSelectors.getCurrency);
    const { vehicles } = yield call(apiService.search.search, searchQuery, currency.id);
    const pageSize = yield select(searchPageSelectors.getPageSize);

    yield put(searchPageActions.searchSuccess(vehicles));
    yield put(searchPageActions.setTotalPages(vehicles.length / pageSize));
    yield put(searchPageActions.setPageVehicles(vehicles.slice(0, pageSize)));
    yield put(searchPageActions.setLoading(false));
  } catch (error) {
    yield put(searchPageActions.setLoading(false));
    yield put(appActions.showError(error.message));
  }
}

function* updateDefaultFilters() {
  const { prices, makes } = yield call(apiService.search.getFilters);
  const makesWithId = enrichMakesWithID(makes);
  const enrichedDefaultFilters = {
    makes: getMakes(makesWithId),
    models: getModels(makesWithId),
    badges: getBadges(makesWithId),
    price: [prices.min, prices.max],
  };

  yield put(searchPageActions.setFieldValue('makesTree', makesWithId));
  yield put(searchPageActions.setDefaultFilters(enrichedDefaultFilters));
  yield put(
    searchPageActions.setAvailableFilters(_.pick(enrichedDefaultFilters, ['models', 'badges'])),
  );

  return enrichedDefaultFilters;
}

export function* setDialogDefaultFilters() {
  try {
    const [activeFilters, editFilters, defaultFilters] = yield all([
      select(searchPageSelectors.getActiveFilters),
      select(searchPageSelectors.getEditFilters),
      select(searchPageSelectors.getDefaultFilters),
    ]);

    const newEditFilters = {
      ...editFilters,
      makes: _.isEmpty(activeFilters.makes) ? [] : activeFilters.makes,
      models: _.isEmpty(activeFilters.models) ? [] : activeFilters.models,
      badges: _.isEmpty(activeFilters.badges) ? [] : activeFilters.badges,
      price: _.isEmpty(activeFilters.price) ? defaultFilters.price : activeFilters.price,
      year: _.isEmpty(activeFilters.year) ? defaultFilters.year : activeFilters.year,
      airport: _.isEmpty(activeFilters.airport) ? {} : activeFilters.airport,
    };

    yield put(searchPageActions.setEditFilters(newEditFilters));
  } catch (error) {
    yield put(appActions.showError(error.message));
  }
}

function* updateMakeFilters(editFilters, activeFilters) {
  const defaultFilters = yield select(searchPageSelectors.getDefaultFilters);
  const { models: defaultModels, badges: defaultBadges } = defaultFilters;
  const { makes: editMakes } = editFilters;
  const { models: activeModels, badges: activeBadges } = activeFilters;

  const availableModels = defaultModels.filter((model) =>
    editMakes.some((make) => make.id === model.make.id),
  );

  const models = activeModels.filter((model) =>
    availableModels.some((e) => e.models.some((m) => m.id === model.id)),
  );

  const availableBadges = defaultBadges.filter((badge) =>
    models.some((model) => model.id === badge.model.id),
  );

  const badges = activeBadges.filter((badge) =>
    availableBadges.some((e) => e.badges.some((b) => b.id === badge.id)),
  );

  return [
    {
      models,
      badges,
    },
    {
      models: availableModels,
      badges: availableBadges,
    },
  ];
}

export function* updateActiveMakeFilters({ makes, models }) {
  try {
    const [editFilters, activeFilters] = yield all([
      select(searchPageSelectors.getEditFilters),
      select(searchPageSelectors.getActiveFilters),
    ]);

    activeFilters.makes = makes || activeFilters.makes;
    activeFilters.models = models || activeFilters.models;
    const [active, available] = yield updateMakeFilters(editFilters, activeFilters);

    yield put(
      searchPageActions.setActiveFilters({
        ...activeFilters,
        ...active,
      }),
    );
    yield put(searchPageActions.setAvailableFilters(available));
  } catch (error) {
    yield put(appActions.showError(error.message));
  }
}

export function* filterMakes({ payload }) {
  yield updateActiveMakeFilters({ makes: payload });
}

export function* filterModels({ payload }) {
  yield updateActiveMakeFilters({ models: payload });
}

function* updateDialogFilters({ makes, models, badges, price }) {
  try {
    const editFilters = yield select(searchPageSelectors.getEditFilters);

    editFilters.makes = makes || editFilters.makes;
    editFilters.models = models || editFilters.models;
    editFilters.badges = badges || editFilters.badges;
    editFilters.price = price || editFilters.price;

    const activeFilters = {
      ...editFilters,
    };

    const [active, available] = yield updateMakeFilters(editFilters, activeFilters);

    yield put(
      searchPageActions.setEditFilters({
        ...editFilters,
        ...active,
      }),
    );
    yield put(searchPageActions.setAvailableFilters(available));
  } catch (error) {
    yield put(appActions.showError(error.message));
  }
}

export function* load() {
  try {
    yield put(searchPageActions.setLoading(true));

    const { makes, models, badges, price } = yield select(searchSelectors.getFilters);

    const getMake = (filters) => {
      const make = filters.makes.filter((e) => makes.includes(e.name));
      return make || [];
    };

    const getMakeModels = (filters) => {
      const makeModels = filters.models.filter((e) => makes.includes(e.make.name));
      if (makeModels) {
        const model = makeModels.flatMap((e) => e.models.filter((m) => models.includes(m.name)));
        return model || [];
      }

      return [];
    };

    const getModelsBadges = (filters) => {
      const modelsBadges = filters.badges.filter((e) =>
        badges.some((b) => b.model === e.model.name),
      );

      if (!modelsBadges) {
        return [];
      }

      const badge = modelsBadges.flatMap((mb) =>
        mb.badges.filter((b) => {
          const modelBadges = badges.find((e) => e.model === mb.model.name);

          if (!Array.isArray(modelBadges.badges)) {
            return modelBadges.badges === b.name;
          }

          return modelBadges.badges.includes(b.name);
        }),
      );

      return badge || [];
    };

    const [defaultFilters, activeFilters] = yield all([
      select(searchPageSelectors.getDefaultFilters),
      select(searchPageSelectors.getActiveFilters),
    ]);

    if (_.isEqual(defaultFilters, initialFilters)) {
      const enrichedFilters = yield updateDefaultFilters();
      const qsMake = getMake(enrichedFilters);
      const qsModels = getMakeModels(enrichedFilters);
      const qsBadges = getModelsBadges(enrichedFilters);

      yield put(
        searchPageActions.setActiveFilters({
          ...initialFilters,
          makes: qsMake,
          models: qsModels,
          badges: qsBadges,
          price,
        }),
      );
      yield updateDialogFilters({
        makes: qsMake,
        models: qsModels,
        badges: qsBadges,
        price,
      });
    } else {
      const qsMake = getMake(defaultFilters);
      const qsModels = getMakeModels(defaultFilters);
      const qsBadges = getModelsBadges(defaultFilters);

      yield put(
        searchPageActions.setActiveFilters({
          ...activeFilters,
          makes: qsMake.length ? qsMake : activeFilters.makes,
          models: qsMake.length || qsModels.length ? qsModels : activeFilters.models,
          badges: qsModels.length || qsBadges.length ? qsBadges : activeFilters.badges,
          price: price.length ? price : [],
        }),
      );

      yield updateDialogFilters({
        ...activeFilters,
        makes: qsMake.length ? qsMake : activeFilters.makes,
        models: qsMake.length || qsModels.length ? qsModels : activeFilters.models,
        badges: qsModels.length || qsBadges.length ? qsBadges : activeFilters.badges,
        price: price.length ? price : [],
      });
    }

    yield put(searchPageActions.search());
  } catch (error) {
    yield put(searchPageActions.setLoading(false));
    yield put(appActions.showError(error.message));
  }
}

export function* loadPage({ payload: pageNumber }) {
  try {
    const vehicles = yield select(searchPageSelectors.getVehicles);
    const pageSize = yield select(searchPageSelectors.getPageSize);

    const startIndex = pageSize * (pageNumber - 1);
    const endIndex = pageSize * pageNumber;
    const pageVehicles = vehicles.slice(startIndex, endIndex);

    yield put(searchPageActions.setPageVehicles(pageVehicles));
    yield put(searchPageActions.setCurrentPage(pageNumber));
  } catch (error) {
    yield put(appActions.showError(error.message));
  }
}
