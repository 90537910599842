import React from 'react';
import PropTypes from 'prop-types';

import { Pagination, PaginationItem } from '@material-ui/lab';

const ResultsPagination = ({ page, count, onChange }) => (
  <Pagination
    page={page}
    count={count}
    boundaryCount={0}
    siblingCount={0}
    renderItem={(item) => {
      if (item.type === 'next' || item.type === 'previous') {
        return <PaginationItem {...item} />;
      }

      return null;
    }}
    variant="outlined"
    onChange={(e, number) => onChange(number)}
  />
);

ResultsPagination.propTypes = {
  page: PropTypes.number,
  count: PropTypes.number,
  total: PropTypes.number,
  onChange: PropTypes.func,
};

ResultsPagination.defaultProps = {
  page: 0,
  count: 0,
  total: 0,
  onChange: () => {},
};

export default ResultsPagination;
