import { connect } from 'react-redux';

import * as searchSelectors from 'store/modules/search/selectors';
import * as searchPageSelectors from 'store/modules/searchPage/selectors';
import SearchVehicleLink from './SearchVehicleLink';

const mapStateToProps = (state) => ({
  tripDates: searchSelectors.getTripDates(state),
  airport: searchPageSelectors.getAirport(state),
});

export default connect(mapStateToProps, null)(SearchVehicleLink);
